import { EventTrackingConfig, withEventTracking } from '@aily/analytics-service';
import * as T from '@aily/graphql-sdk/schema';
import { ClosableModal, ResponsiveScaleContainer, useProFiltersContext } from '@aily/saas-core';
import { Close } from '@aily/saas-core/icons';
import { ButtonProps, IconButton, styled } from '@mui/material';
import React, { useLayoutEffect, useRef } from 'react';

import { OpeningScreen, RealSlider } from './components';
import { BottomControls } from './components/BottomControls';
import { RealModalProvider, useRealModal } from './RealModalProvider';

const CONTENT_BACKGROUND = 'linear-gradient(185deg, #31D4B6 0%, #114F44 5%, #111521 10%, #000 15%)';

const CloseButton = styled((props: ButtonProps) => (
  <IconButton {...props}>
    <Close />
  </IconButton>
))(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
  top: 50,
  right: 30,
  color: theme.palette.text.secondary,
  textTransform: 'none',
  ...theme.typography.body,
  '&:hover, &:focus, &.Mui-selected, &:hover.Mui-selected': {
    color: theme.palette.text.primary,
    background: 'transparent',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
}));

type ContentProps = {
  onTrackClose?: (real: T.Real, screen?: T.Maybe<T.RealScreen>, filters?: string[]) => void;
  onTrackMute?: (real: T.Real, screen?: T.Maybe<T.RealScreen>, filters?: string[]) => void;
  onTrackClickButton?: (real: T.Real, screen?: T.Maybe<T.RealScreen>, filters?: string[]) => void;
  onTrackUnmute?: (real: T.Real, screen?: T.Maybe<T.RealScreen>, filters?: string[]) => void;
  onTrackSeen?: (real: T.Real, screen?: T.Maybe<T.RealScreen>, filters?: string[]) => void;
  onTrackSwipe?: (real: T.Real, screen?: T.Maybe<T.RealScreen>, filters?: string[]) => void;
};

const RealModalContent: React.FC<ContentProps> = (props) => {
  const {
    data,
    showOpeningScreen,
    activeIndex,
    visibleCards,
    pauseAudio,
    audio,
    audioProgressPercentage,
    onNext,
    onBack,
    handleClose,
    handleSetActiveIndex,
    handleToggleAudio,
  } = useRealModal();
  const isMounted = useRef(false);
  const proFiltersContext = useProFiltersContext();
  const activeScreen = data?.screens[activeIndex + 1];
  const proFilters = proFiltersContext?.selectedFilters?.map((filter) => filter.label);

  useLayoutEffect(() => {
    if (isMounted.current) {
      if (activeScreen) {
        props.onTrackSeen?.(data, activeScreen, proFilters);
      }
    } else {
      isMounted.current = true;
    }
  }, [activeIndex]);

  if (!data) {
    return null;
  }

  const handleTogglePlayButton = () => {
    handleToggleAudio();
    if (!pauseAudio) {
      props.onTrackMute?.(data, activeScreen, proFilters);
    } else {
      props.onTrackUnmute?.(data, activeScreen, proFilters);
    }
  };

  const onCloseReal = () => {
    handleClose();
    props.onTrackClose?.(data, activeScreen, proFilters);
  };

  const handleNext = () => {
    onNext();
    props.onTrackSwipe?.(data, activeScreen, proFilters);
  };

  const handleBack = () => {
    onBack();
    props.onTrackSwipe?.(data, activeScreen, proFilters);
  };

  const onClickLetsDo = () => {
    props.onTrackClickButton?.(data, activeScreen, proFilters);
  };

  return (
    <>
      <CloseButton onClick={onCloseReal} data-testid="close-button" />
      <ResponsiveScaleContainer containerWidth={1920} containerHeight={834}>
        <>
          {showOpeningScreen && (
            <OpeningScreen
              screen={data?.screens?.[0] as T.RealScreen}
              pauseAudio={pauseAudio}
              onAudioEnd={handleNext}
              audio={<div>{audio}</div>}
            />
          )}

          {!showOpeningScreen && (
            <RealSlider
              data={data as T.Real}
              activeIndex={activeIndex}
              visibleCards={visibleCards}
              onSetActiveIndex={handleSetActiveIndex}
              onClose={onCloseReal}
              onClickLetsDo={onClickLetsDo}
              pauseAudio={pauseAudio}
              audio={<div>{audio}</div>}
            />
          )}
        </>
      </ResponsiveScaleContainer>
      <BottomControls
        isAudioPlaying={!pauseAudio}
        isOpeningScreenVisible={showOpeningScreen}
        onToggleAudioState={handleTogglePlayButton}
        onNext={handleNext}
        onBack={handleBack}
        audioProgressPercentage={audioProgressPercentage}
      />
    </>
  );
};

const trackingConfig: EventTrackingConfig<ContentProps> = {
  onTrackClose: {
    eventName: 'real.closed',
    getEventProps: (real, screen, filters) => ({
      component: 'real',
      intent: 'close',
      item_type: 'button',
      event_version: '2.1.0',
      name: real.title,
      component_id: real.id,
      component_type: real.insightsType,
      screen_id: screen?.screenId,
      filter_name: filters,
    }),
  },
  onTrackSwipe: {
    eventName: 'real.swiped',
    getEventProps: (real, screen, filters) => ({
      component: 'real',
      intent: 'swipe',
      item_type: 'button',
      event_version: '2.1.0',
      name: real.title,
      component_id: real.id,
      component_type: real.insightsType,
      screen_id: screen?.screenId,
      filter_name: filters,
    }),
  },
  onTrackSeen: {
    eventName: 'real.seen',
    getEventProps: (real, screen, filters) => ({
      component: 'real',
      intent: 'see',
      item_type: 'real',
      event_version: '2.1.0',
      name: real.title,
      component_id: real.id,
      component_type: real.insightsType,
      screen_id: screen?.screenId,
      filter_name: filters,
    }),
  },
  onTrackClickButton: {
    eventName: 'real.clicked',
    getEventProps: (real, screen, filters) => ({
      component: 'real',
      intent: 'click',
      item_type: 'button',
      component_value: 'lets_do_it',
      name: real.title,
      component_id: real.id,
      component_type: real.insightsType,
      screen_id: screen?.screenId,
      filter_name: filters,
      event_version: '2.1.0',
    }),
  },
  onTrackMute: {
    eventName: 'real.clicked',
    getEventProps: (real, screen, filters) => ({
      component: 'real',
      intent: 'click',
      item_type: 'button',
      component_value: 'mute',
      name: real.title,
      component_id: real.id,
      component_type: real.insightsType,
      screen_id: screen?.screenId,
      filter_name: filters,
      event_version: '2.1.0',
    }),
  },
  onTrackUnmute: {
    eventName: 'real.clicked',
    getEventProps: (real, screen, filters) => ({
      component: 'real',
      intent: 'click',
      item_type: 'button',
      component_value: 'unmute',
      name: real.title,
      component_id: real.id,
      screen_id: screen?.screenId,
      filter_name: filters,
      event_version: '2.1.0',
    }),
  },
};

export const TrackedRealModalContent = withEventTracking(RealModalContent, trackingConfig);

interface RealModalProps {
  onClose: () => void;
  data?: T.Real;
  open: boolean;
}

export function RealModal({ onClose, data, open }: RealModalProps) {
  if (!open) {
    return null;
  }

  return (
    <ClosableModal
      open={open}
      ContentContainerProps={{ minWidth: '100%' }}
      ContentBackgroundProps={{
        sx: { background: CONTENT_BACKGROUND, justifyContent: 'center' },
      }}
    >
      <RealModalProvider data={data} onClose={onClose}>
        <TrackedRealModalContent />
      </RealModalProvider>
    </ClosableModal>
  );
}
