import { StoryDecision } from '@aily/graphql-sdk/schema';
import { isEqual } from 'lodash-es';
import React from 'react';

import {
  ClosableModal,
  ClosableModalProps,
  ResponsiveScaleContainer,
} from '../../../../components';
import { AgentJsonData, Screen } from '../../types/agentJsonData';
import { AgentSlideshowBuilder } from './AgentSlideshowBuilder';
import HeaderComponent from './HeaderComponent';

export interface AgentSlideshowModalProps extends Omit<ClosableModalProps, 'children'> {
  agentJsonData: AgentJsonData;
  hideSoundControl?: boolean;
  onAfterScreen?: (screen: Screen) => React.ReactNode;
  baseAudioURL: string;
  decision: StoryDecision | null;
  onBackdropClose?: (forceRefetch: boolean) => void;
}

const contentBackground = 'linear-gradient(185deg, #31D4B6 0%, #114F44 5%, #111521 10%, #000 15%)';

export const AgentSlideshowModal: React.FC<AgentSlideshowModalProps> = React.memo(
  ({
    agentJsonData,
    hideSoundControl,
    onAfterScreen,
    baseAudioURL,
    decision,
    onClose,
    onBackdropClose,
    ...rest
  }) => (
    <ClosableModal
      ContentBackgroundProps={{ sx: { background: contentBackground } }}
      CloseButtonProps={{ sx: { top: 10, fontSize: '28px' } }}
      {...rest}
    >
      <>
        <HeaderComponent onClose={onClose} />
        <ResponsiveScaleContainer containerWidth={1250} containerHeight={834}>
          <AgentSlideshowBuilder
            agentJsonData={agentJsonData}
            hideSoundControl={hideSoundControl}
            onAfterScreen={onAfterScreen}
            baseAudioURL={baseAudioURL}
            decision={decision}
            onAgentClose={onClose}
            onBackdropClose={onBackdropClose}
          />
        </ResponsiveScaleContainer>
      </>
    </ClosableModal>
  ),
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
