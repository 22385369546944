import { Box, styled } from '@mui/material';
import React from 'react';

import { Slideshow, SlideshowProps } from '../../../../components/Slideshow';

export interface AgentSlideshowProps extends SlideshowProps {
  hideSoundControl?: boolean;
}

const StyledSlideshow = styled(Slideshow)(() => ({ paddingTop: 0 }));

export const AgentSlideshow: React.FC<AgentSlideshowProps> = ({ children, ...rest }) => {
  return (
    <Box position="relative">
      <StyledSlideshow profile="AGENT" {...rest}>
        {children}
      </StyledSlideshow>
    </Box>
  );
};
